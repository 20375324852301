<template>
  <div class="DAV-workshop__trunkFull">
    <spinner v-if="isLoading" />
    <header class="DAV-topBar">
      <div class="DAV-topBar__select">
        <Dropdown
          :value="page.value"
          :options="page.options"
          type="small"
          @updateTarget="v => setPage(v)"
        />
      </div>
      <div class="DAV-topBar__device">
        <button
          class="DAV-topBar__device-pc"
          :class="{'DAV-topBar__device--active': deviceType === 'desktop'}"
          @click="setDeviceType('desktop')"
        >
          pc
        </button>
        <button
          class="DAV-topBar__device-mobile"
          :class="{'DAV-topBar__device--active': deviceType === 'mobile'}"
          @click="setDeviceType('mobile')"
        >
          mobile
        </button>
      </div>
      <button class="DAV-topBar__prev" @click="closePopup">
        테마 미리보기 닫기
      </button>
    </header>
    <div class="DAV-preview">
      <div class="DAV-preview__shop" :class="setPreviewClass">
        <iframe
          id="theme-preview"
          :src="url"
          style="width: 100%; height: 100%; border: 0"
          scrolling="no"
        />
      </div>
    </div>
  </div>
</template>

<script>
import {createNamespacedHelpers} from "vuex";

const ModuleAlert = createNamespacedHelpers("ModuleAlert");
const ModuleTheme = createNamespacedHelpers("ModuleTheme");
const ModuleEditor = createNamespacedHelpers("ModuleEditor");

export default {
  data() {
    return {
      page: {
        options: [],
        value: 0
      },
      deviceType: null,
      isLoading: false
    };
  },
  computed: {
    // class binding - 디바이스 타입
    setPreviewClass() {
      return {
        "DAV-preview__shop--mobile": this.deviceType === "mobile",
        "DAV-preview__shop--desktop": this.deviceType === "desktop"
      };
    },
    url() {
      return this.getMode === "partner"
        ? `/preview/${this.getThemeId}?mode=partner`
        : `/preview/${this.getThemeId}`;
    },
    ...ModuleTheme.mapGetters(["getSaveHistoryListData", "getThemeLoadData"]),
    ...ModuleEditor.mapGetters(["getThemeId", "getPopup", "getMode"])
  },
  mounted() {
    // 디바이스 선택
    this.deviceType =
      document.documentElement.clientWidth < 1024 ? "mobile" : "desktop";

    // 컴포넌트 불러오기
    if (this.$route.query.mode === "partner") {
      this.settingPreview("partner");
    } else {
      this.saveHistoryList();
    }
  },
  methods: {
    // 저장 히스토리 목록
    saveHistoryList() {
      let payload = {};
      payload.themeId = this.getThemeId;
      payload.perPage = 1;

      this.isLoading = true;
      this.actSaveHistoryList(payload).then(() => {
        this.settingPreview();
      });
    },
    // 저장 히스토리목록 조회 후 처리함수 or 파트너 조건으로 미리보기 진입 시 해당 함수 바로실행
    settingPreview(mode) {
      let theme = null;

      if (mode === "partner") {
        this.isLoading = true;
        theme = this.getThemeLoadData;
      } else {
        theme = this.getSaveHistoryListData.list.length
          ? this.getSaveHistoryListData.list[0].data
          : this.getThemeLoadData;
      }

      // page options set
      theme.editablePages.map((i, index) => {
        this.page.options.push({text: i.name, value: index});
      });

      // data send to iframe
      setTimeout(() => {
        document.getElementById("theme-preview").contentWindow.postMessage(
          {
            action: "init",
            id: "theme-preview",
            theme,
            device: this.deviceType
          },
          `${window.location.origin}/preview`
        );
      }, 1000);
      this.isLoading = false;
    },
    // 페이지 선택
    setPage(index) {
      this.page.value = index;
      // page send to iframe
      document
        .getElementById("theme-preview")
        .contentWindow.postMessage(
          {action: "page", page: index},
          `${window.location.origin}/preview`
        );
    },
    // 디바이스 선택
    setDeviceType(deviceType) {
      this.deviceType = deviceType;
      // device send to iframe
      document
        .getElementById("theme-preview")
        .contentWindow.postMessage(
          {action: "device", device: this.deviceType},
          `${window.location.origin}/preview`
        );
    },
    // 팝업 닫기
    closePopup() {
      this.setPopup({page: null});
      if (this.$route.params.preview) {
        window.close();
      } else {
        this.setPopup({page: null});
      }
    },
    ...ModuleEditor.mapMutations(["setPopup"]),
    ...ModuleTheme.mapActions(["actSaveHistoryList"]),
    ...ModuleAlert.mapMutations(["setAlert"])
  },
  components: {
    Spinner: () => import("@/components/basis/spinner.vue")
  }
};
</script>

<style lang="scss" scoped>
.DAV-workshop__trunkFull {
  z-index: 12;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #e7e9ec;
}

.DAV-topBar__select::v-deep .DAV-dropdown button {
  height: 36px;
  font-size: 14px;
}

.DAV-topBar {
  border-bottom: none;

  &__select {
    position: absolute;
    top: 10px;
    left: 18px;
    width: 200px;
    height: 36px;
    font-size: 16px;
  }

  &__prev {
    position: absolute;
    top: 16px;
    right: 25px;
    width: 25px;
    height: 25px;
    background-image: url($path + "btn_close_popup.svg");
    background-repeat: no-repeat;
    background-position: center;
    text-indent: -9999px;
    background-size: cover;
  }
}
.DAV-preview {
  position: fixed;
  top: 56px;
  right: 0px;
  bottom: 0;
  left: 0;
  background-color: #e7e9ec;

  &__shop {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: hidden;
    background-color: #ffffff;
  }
  &__shop--desktop {
    margin: 8px;
  }
}
/* 반응형 - desktop */
@media screen and (min-width: 1024px) {
  .DAV-preview__shop--mobile {
    width: 421px;
    left: 50%;
    transform: translateX(-50%);
  }
}
/* 반응형 - mobile */
@media screen and (max-width: 1023px) {
  .DAV-topBar__device {
    right: 64px;
  }
  .DAV-preview__shop {
    &--desktop {
      margin: 0;
    }
  }
}
</style>
